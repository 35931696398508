import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import crew from './static/Crew.png'
import main from './static/Main.png'
import map from './static/Map.png'
import rank from './static/Rank.png'
import stash from './static/Stash.png'


// React.lazy only supports default exports for now see https://reactjs.org/docs/code-splitting.html
const SnakeGame = React.lazy(() => import('./minigames'));

class SmallLoadingSpinner extends React.Component {
    render() {
        return (
            <div className={this.props.hidden ? "smallLoadingSpinner" : "hiddenPage"} ></div>
        )
    }
}

class LoginScreen extends React.Component {
    render() {
        return (
            <div className={this.props.hidden ? "page" : "hiddenPage"} onSubmit={this.props.login}>
                <form className="loginForm">
                    <label>Email:</label>
                    <input type="text" onChange={this.props.handleEmailChange} required autoFocus></input>
                    <label>Password:</label>
                    <input id="passwordInput" type="password" onChange={this.props.handlePasswordChange} required></input>
                    <div className="flexer"><label>Forgot Password?</label><div className="switchLoginButton" onClick={this.props.handleGoToResetPasswordScreen}>Reset Password</div></div>
                    <div className="flexer loginCheckbox"><label>Stay Signed In:</label><input id="staySignedIn" type="checkbox" onChange={this.props.handleStayInChange}></input></div>
                    <button type="submit">Login</button>
                    <div className="flexer"><label>Don't have an account?</label><div className="switchLoginButton" onClick={this.props.handleGoToCreateUserScreen}>Create One</div></div>
                </form>
            </div>
        )
    }
}

class ResetPasswordScreen extends React.Component {
    render() {
        return (
            <div className={this.props.hidden ? "page" : "hiddenPage"} onSubmit={this.props.reset}>
                <form className="loginForm">
                    <label>Email:</label>
                    <input type="text" onChange={this.props.handleEmailChange} required autoFocus></input>
                    <button type="submit">Reset</button>
                    <div className="flexer"><label>Back to </label><div className="switchLoginButton" onClick={this.props.handleGoToLoginScreen}>Login</div></div>
                </form>
            </div>
        )
    }
}

class CreateUserScreen extends React.Component {
    render() {
        return (
            <div className={this.props.hidden ? "page" : "hiddenPage"} onSubmit={this.props.createUser}>
                <form className="loginForm">
                    <label>Username:</label>
                    <input type="text" onChange={this.props.handleUsernameChange} required autoFocus></input>
                    <label>Email:</label>
                    <input type="email" onChange={this.props.handleEmailChange} required></input>
                    <label>Password:</label>
                    <input id="passwordInput1" type="password" onChange={this.props.handlePassword1Change} required></input>
                    <label>Confirm Password:</label>
                    <input id="passwordInput2" type="password" onChange={this.props.handlePassword2Change} required></input>
                    <div className="flexer loginCheckbox"><label>I have read and agree to the <a target="_blank" rel="noreferrer" href="https://stashed-cash.herokuapp.com/static/hunts/StashedCashTermsofUse.pdf">Terms of Use</a> and <a target="_blank" rel="noreferrer" href="https://stashed-cash.herokuapp.com/static/hunts/StashedCashPrivacyPolicy.pdf">Privacy Policy</a></label><input id="agreeToTerms" type="checkbox" required></input></div>
                    <button type="submit">Create</button>
                    <div className="flexer"><label>Already have an account?</label><div className="switchLoginButton" onClick={this.props.handleGoToLoginScreen}>Login</div></div>
                </form>
            </div>
        )
    }
}

class LoadingScreen extends React.Component {
    render() {
        return (
            <div className="loadingScreen"></div>
        )
    }
}

class ClueBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPreviousClues: false,
        };
    }

    handlePreviousClueClick = () => {
        this.setState({
            showPreviousClues: true,
        })
    }

    handleCurrentClueClick = () => {
        this.setState({
            showPreviousClues: false,
        })
    }

    render() {
        if (!this.state.showPreviousClues) {
            return (
                <div className="page">
                    <div className="infoBox">
                    <div className={this.props.clues.length > 1 ? "previousClues switchLoginButton" : "hiddenClue"} onClick={this.handlePreviousClueClick}>Previous Clues</div>
                        <div id="resultText">
                            <div id="currentResult">
                                <div className={this.props.clues[this.props.clues.length - 1].question !== "" ? "clue" : "hiddenClue"}>{this.props.clues[this.props.clues.length - 1].question}</div>
                                <img className={this.props.clues[this.props.clues.length - 1].question_image !== "" ? "clue" : "hiddenClue"} src={this.props.clues[this.props.clues.length - 1].question_image} alt={this.props.clues[this.props.clues.length - 1].question_image} />
                                <div className={this.props.clues[this.props.clues.length - 1].clue !== "" ? "clue" : "hiddenClue"}>{this.props.clues[this.props.clues.length - 1].clue}</div>
                                <img className={this.props.clues[this.props.clues.length - 1].clue_image !== "" ? "clue" : "hiddenClue"} src={this.props.clues[this.props.clues.length - 1].clue_image} alt={this.props.clues[this.props.clues.length - 1].question_image} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="page">
                    <div className="previousCluesBox infoBox">
                        <div className="previousCluesWrapper">
                            <div className="previousClues switchLoginButton" onClick={this.handleCurrentClueClick}>Current Clue</div>
                            {this.props.clues.map((clue, index) => (
                                <div id="resultText" key={index}>
                                    <div className="previousClueNumber">{index+1}.</div>
                                    <div id="currentResult">
                                        <div className={clue.question !== "" ? "clue" : "hiddenClue"}>{clue.question}</div>
                                        <img className={clue.question_image !== "" ? "clue" : "hiddenClue"} src={clue.question_image} alt={clue.question_image} />
                                        <div className={clue.clue !== "" ? "clue" : "hiddenClue"}>{clue.clue}</div>
                                        <img className={clue.clue_image !== "" ? "clue" : "hiddenClue"} src={clue.clue_image} alt={clue.question_image} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

class KeywordBox extends React.Component {
    render() {
        switch (this.props.miniGame) {
            case "Snake":
                return (
                    <div id="keywordBox" className={this.props.hidden ? "page" : "hiddenPage"}>
                        <div className="minigameKeywordSearch">
                            <form id="keywordForm" autoComplete="off" onSubmit={this.props.handleKeyword}>
                                <label htmlFor="enterKeyword">Keyword:</label><br />
                                <input type="text" id="enterKeyword" name="enterKeyword" onChange={this.props.handleChange} /><br />
                            </form>
                        </div>
                        <Suspense fallback={<LoadingScreen />}>
                            <SnakeGame />
                        </Suspense>
                    </div>
                )
            default:
                return (
                    <div id="keywordBox" className={this.props.hidden ? "page" : "hiddenPage"}>
                        <div className="keywordSearch">
                            <form id="keywordForm" autoComplete="off" onSubmit={this.props.handleKeyword}>
                                <label htmlFor="enterKeyword">Keyword:</label><br />
                                <input type="text" id="enterKeyword" name="enterKeyword" onChange={this.props.handleChange} /><br />
                            </form>
                        </div>
                        <ClueBox {...this.props} />
                    </div>
                )
        }
    }
}

class MapBox extends React.Component {
    render() {
        return (
            <div id="mapBox" className={this.props.hidden ? "page" : "hiddenMapPage"}>
                <object data={"https://www.openstreetmap.org/export/embed.html?bbox=" + this.props.lat + "%2C" + this.props.latPlus + "%2C" + this.props.long + "%2C" + this.props.longPlus + "&amp;layer=mapnik"} width="100%" height="100%">
                </object>
            </div>
        )
    }
}

class StashBox extends React.Component {
    render() {
        return (
            <div id="stashBox" className={this.props.hidden ? "page" : "hiddenPage"}>
                <div className="infoBox">
                    <div>{this.props.hunt.name}</div>
                    <div>{this.props.hunt.start_date}</div>
                    <div><b>Prizes: </b>{this.props.hunt.prizes}</div>
                </div>
            </div>
        )
    }
}

class CrewBox extends React.Component {
    render() {
        return (
            <div id="crewBox" className={this.props.hidden ? "page" : "hiddenPage"}>
                <div className="infoBox">
                    {this.props.team_desc}
                </div>
            </div>
        )
    }
}

class RankBox extends React.Component {
    render() {
        return (
            <div id="rankBox" className={this.props.hidden ? "page" : "hiddenPage"}>
                <div className="settingsMenu">
                    <div className="settingsMenuHeader"><div className="opaqueTrickery">Settings</div></div>
                    <div className="settingList">
                        <div className="settingCategory">
                            <div className="settingCategoryHeading"><div className="opaqueTrickery">Account</div></div>
                            <div className="settingCategoryList">
                                <div className="settingCategoryListItem"><div className="opaqueTrickery">Account Information</div></div>
                                <div className="settingCategoryListItem"><div className="opaqueTrickery">Notifactions & Messages</div></div>
                                <div className="settingCategoryListItem"><div className="opaqueTrickery">Previous Hunts</div></div>
                            </div>
                        </div>
                        <div className="settingCategory">
                            <div className="settingCategoryHeading"><div className="opaqueTrickery">Security and Privacy</div></div>
                            <div className="settingCategoryList">
                                <div className="settingCategoryListItem"><div className="opaqueTrickery"><a target="_blank" rel="noreferrer" href="https://stashed-cash.herokuapp.com/static/hunts/StashedCashTermsofUse.pdf">Terms of Use</a></div></div>
                                <div className="settingCategoryListItem"><div className="opaqueTrickery"><a target="_blank" rel="noreferrer" href="https://stashed-cash.herokuapp.com/static/hunts/StashedCashPrivacyPolicy.pdf">Privacy Policy</a></div></div>
                            </div>
                        </div>
                        <div className="settingCategory">
                            <div className="settingCategoryHeading"><div className="opaqueTrickery">Preferences</div></div>
                            <div className="settingCategoryList">
                                <div className="settingCategoryListItem"><div className="opaqueTrickery">App Appearance</div></div>
                            </div>
                        </div>
                        <div className="logOut"><div className="opaqueTrickery" onClick={() => this.props.onLogoutCLick()}>Log Out</div></div>
                    </div>
                </div>
            </div>
        )
    }
}

class Navbar extends React.Component {
    render() {
        return (
            <div id="navbar" className={this.props.hidden ? "" : "hiddenPage"}>
                <div id="navContainer">
                    <img className="navButton" src={crew} alt="Crew" onClick={() => this.props.onClick('crewBox')} />
                    <img className="navButton" src={map} alt="Map" onClick={() => this.props.onClick('mapBox')} />
                    <img className="navButton" src={main} alt="Main" onClick={() => this.props.onClick('keywordBox')} />
                    <img className="navButton" src={stash} alt="Stash" onClick={() => this.props.onClick('stashBox')} />
                    <img className="navButton" src={rank} alt="Rank" onClick={() => this.props.onClick('rankBox')} />
                </div>
            </div>
        )
    }
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

class SC extends React.Component {
    constructor(props) {
        super(props);
        var u = JSON.parse(sessionStorage.getItem("userInfo"));
        if(u === null || u.loginToken === null || u.loginToken === "") {
            let cu = getCookie("username");
            let ct = getCookie("loginToken");
            u = {
                'username': cu,
                'loginToken': ct,
            }
        }
        let t = "";
        let uname = "";
        let cPage = "";
        if (u !== null) {
            t = u.loginToken;
            uname = u.username;
            cPage = 'keywordBox';
            if (t === null || t === "") {
                t = "";
                cPage = "";
            }
        }
        this.state = {
            currentPage: cPage,
            keyword: 'test',
            clues: [
                {
                    question: "Getting Hunt Info",
                    clue: "",
                    question_image: "",
                    clue_image: "",
                }
            ],
            hunts: [{
                'name': 'Unknown',
                'start_date': "",
                'end_date': "",
                'prizes': "TBD",
                "lat": "-113.83277893066408",
                "latPlus": "36.98856817890231",
                "long": "-113.37959289550783",
                "longPlus": "37.220760287997166",
            }],
            miniGame: "",
            loginInfo: {
                'username': uname,
                'password1': "",
                'password2': "",
                'email': "",
                'token': t,
                'stayIn': false,
            },
            smallSpinner: false,
            bigSpinner: false,
        };
    }

    componentDidMount() {
        this.getUser();
        this.timer = setInterval(() => {
            this.getUser();
        }, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    handleClick(page) {
        if (this.state.loginInfo.token !== "") {
            this.setState({
                currentPage: page,
            });
        }
    }

    handleKeywordChange(event) {
        this.setState({
            keyword: event.target.value,
        });
    }

    handleLoginInfoChange(event, propName, createScreen = 'no') {
        this.setState({
            loginInfo: {
                ...this.state.loginInfo,
                [propName]: event.target.value,
                'createUserScreen': createScreen,
            },
        });
    }

    handleStayInChange(event) {
        this.setState({
            loginInfo: {
                ...this.state.loginInfo,
                'stayIn': !this.state.loginInfo.stayIn,
            },
        });
    }

    handleGoToCreateUserScreen(event) {
        this.setState({
            loginInfo: {
                ...this.state.loginInfo,
                'createUserScreen': 'yes',
                'resetPasswordScreen': '',
            },
        });
    }

    handleGoToLoginScreen(event) {
        this.setState({
            loginInfo: {
                ...this.state.loginInfo,
                'createUserScreen': '',
                'resetPasswordScreen': '',
            },
        });
    }

    handleGoToResetPasswordScreen(event) {
        this.setState({
            loginInfo: {
                ...this.state.loginInfo,
                'createUserScreen': '',
                'resetPasswordScreen': 'yes',
            },
        });
    }

    login(event) {
        var that = this;
        this.showSmallSpinner();
        fetch(process.env.REACT_APP_DATABASE_URL + '/dj-rest-auth/login/', {
            method: 'POST',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ email: this.state.loginInfo.email, password: this.state.loginInfo.password1 })
        })
            .then(function (response) {
                if (!response.ok) {
                    return response.text().then(text => { throw new Error(text) })
                }
                return response;
            })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    currentPage: 'keywordBox',
                    miniGame: data.q_type === 'GME' ? data.message_list[1].text : "",
                    loginInfo: {
                        'username': this.state.loginInfo.username,
                        'password1': "",
                        'password2': "",
                        'email': this.state.loginInfo.email,
                        'token': data.key,
                        'stayIn': this.state.loginInfo.stayIn,
                    },
                });
                var userInfo = {
                    "loginToken": data.key,
                    "username": this.state.loginInfo.username,
                }
                sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
                if(this.state.loginInfo.stayIn) {
                    var expiryDate = new Date();
                    expiryDate.setMonth(expiryDate.getMonth() + 1);
                    document.cookie = "username=" + userInfo.username + "; expires=" + expiryDate.toDateString() +"; path=/;secure;httpnly;";
                    document.cookie = "loginToken=" + userInfo.loginToken + "; expires=" + expiryDate.toDateString() +"; path=/;secure;httpnly;";
                }
                document.getElementById('passwordInput').value = "";
                document.getElementById('passwordInput1').value = "";
                document.getElementById('passwordInput2').value = "";
                this.getUser();
                this.hideSmallSpinner();
            })
            .catch(function (error) {
                console.log(error);
                alert(error);
                that.hideSmallSpinner();
            });
        event.preventDefault();
    }

    logout() {
        this.showSmallSpinner();
        fetch(process.env.REACT_APP_DATABASE_URL + '/dj-rest-auth/logout/', {
            method: 'POST',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        })
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    currentPage: '',
                    miniGame: data.q_type === 'GME' ? data.message_list[1].text : "",
                    loginInfo: {
                        'username': this.state.loginInfo.username,
                        'password1': "",
                        'password2': "",
                        'email': this.state.loginInfo.email,
                        'token': "",
                    },
                });
                this.handleClick('keywordBox');
                var userInfo = {
                    "loginToken": "",
                    "username": this.state.loginInfo.username,
                }
                sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
                document.cookie = "username=; path=/;secure;httpnly;";
                document.cookie = "loginToken=; path=/;secure;httpnly;";
                this.hideSmallSpinner();
            })
            .catch(function (error) {
                console.log(error);
                this.hideSmallSpinner();
            });
    }

    async getUser() {
        var that = this;
        if(this.state.loginInfo.token !== "") {
            this.showSmallSpinner();
        fetch(process.env.REACT_APP_DATABASE_URL + '/get_user/', {
            method: 'GET',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Token ' + this.state.loginInfo.token,
            },
        })
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(data => {
                var clues_temp = [];
                var i;
                for (i = 0; i < data.message_list.length; i++) {
                    clues_temp.push({
                        question: data.message_list[i] != null ? data.message_list[i].instruction_text : "",
                        clue: data.message_list[i] != null ? data.message_list[i].text : "",
                        question_image: data.message_list[i] != null ? data.message_list[i].instruction_images[0] : "",
                        clue_image: data.message_list[i] != null ? data.message_list[i].images[0] : "",
                    });
                }
                this.setState({
                    clues: clues_temp,
                    hunts: [{
                        'name': data.hunt_name,
                        'start_date': data.hunt_start,
                        'end_date': data.hunt_end,
                        'prizes': data.hunt_prizes,
                        'team_desc': data.hunt_team_desc,
                        'lat': data.hunt_lat,
                        'latPlus': data.hunt_latPlus,
                        'long': data.hunt_long,
                        'longPlus': data.hunt_longPlus,
                    }],
                });
                this.hideSmallSpinner();
                return data;
            })
            .catch(function (error) {
                console.log(error);
                that.hideSmallSpinner();
            });
        }
    }

    resetPassword(event) {
        var that = this;
        this.showSmallSpinner();
        fetch(process.env.REACT_APP_DATABASE_URL + '/dj-rest-auth/password/reset/', {
            method: 'POST',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ email: this.state.loginInfo.email})
        })
            .then(function (response) {
                if (!response.ok) {
                    return response.text().then(text => { throw new Error(text) })
                }
                return response;
            })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    currentPage: '',
                    miniGame: data.q_type === 'GME' ? data.message_list[1].text : "",
                    loginInfo: {
                        'username': this.state.loginInfo.username,
                        'password1': "",
                        'password2': "",
                        'email': this.state.loginInfo.email,
                        'token': "",
                    },
                });
                this.handleClick('keywordBox');
                var userInfo = {
                    "loginToken": "",
                    "username": this.state.loginInfo.username,
                }
                sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
                this.hideSmallSpinner();
                alert("Reset password not working, contact admin.");
            })
            .catch(function (error) {
                console.log(error);
                alert(error);
                that.hideSmallSpinner();
            });
        event.preventDefault();
    }

    createUser(event) {
        var that = this;
        this.showSmallSpinner();
        fetch(process.env.REACT_APP_DATABASE_URL + '/dj-rest-auth/registration/', {
            method: 'POST',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ username: this.state.loginInfo.username, password1: this.state.loginInfo.password1, password2: this.state.loginInfo.password2, email: this.state.loginInfo.email })
        })
            .then(function (response) {
                if (!response.ok) {
                    return response.text().then(text => { throw new Error(text) })
                }
                return response;
            })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    miniGame: data.q_type === 'GME' ? data.message_list[1].text : "",
                    loginInfo: {
                        'username': this.state.loginInfo.username,
                        'password1': this.state.loginInfo.password1,
                        'password2': this.state.loginInfo.password2,
                        'email': this.state.loginInfo.email,
                        'token': this.state.loginInfo.token,
                        'createUserScreen': '',
                    },
                });
                document.getElementById('passwordInput').value = "";
                document.getElementById('passwordInput1').value = "";
                document.getElementById('passwordInput2').value = "";
                this.hideSmallSpinner();
            })
            .catch(function (error) {
                console.log(error);
                alert(error);
                that.hideSmallSpinner();
            });
        event.preventDefault();
    }

    checkKeyword(event) {
        var that = this;
        this.showSmallSpinner();
        fetch(process.env.REACT_APP_DATABASE_URL + '/check_keyword/' + this.state.keyword + '/', {
            method: 'POST',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Token ' + this.state.loginInfo.token
            },
        })
            .then(function (response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(data => {
                var clues_temp = [];
                var i;
                for (i = 0; i < data.message_list.length; i++) {
                    clues_temp.push({
                        question: data.message_list[i] != null ? data.message_list[i].instruction_text : "",
                        clue: data.message_list[i] != null ? data.message_list[i].text : "",
                        question_image: data.message_list[i] != null ? data.message_list[i].instruction_images[0] : "",
                        clue_image: data.message_list[i] != null ? data.message_list[i].images[0] : "",
                    });
                }
                this.setState({
                    currentPage: 'keywordBox',
                    clues: clues_temp,
                    miniGame: data.q_type === 'GME' ? data.message_list[1].text : "",
                });
                this.hideSmallSpinner();
            })
            .catch(function (error) {
                console.log(error);
                that.hideSmallSpinner();
            });
        event.preventDefault();
    }

    showSmallSpinner() {
        this.setState({
            smallSpinner: true,
        });
    }

    hideSmallSpinner() {
        this.setState({
            smallSpinner: false,
        });
    }

    render() {
        const cPage = this.state.currentPage;
        return (
            <div id="SC">
                <SmallLoadingSpinner hidden={this.state.smallSpinner} />
                <LoginScreen hidden={this.state.loginInfo.token === "" && this.state.loginInfo.createUserScreen !== "yes" && this.state.loginInfo.resetPasswordScreen !== "yes"} login={(k) => this.login(k)} handleEmailChange={(k) => this.handleLoginInfoChange(k, 'email')} handlePasswordChange={(k) => this.handleLoginInfoChange(k, 'password1')} handleStayInChange={(k) => this.handleStayInChange(k)} handleGoToCreateUserScreen={(k) => this.handleGoToCreateUserScreen(k)} handleGoToResetPasswordScreen={(k) => this.handleGoToResetPasswordScreen(k)} />
                <ResetPasswordScreen hidden={this.state.loginInfo.token === "" && this.state.loginInfo.resetPasswordScreen === "yes"} reset={(k) => this.resetPassword(k)} handleEmailChange={(k) => this.handleLoginInfoChange(k, 'email')} handleGoToLoginScreen={(k) => this.handleGoToLoginScreen(k)} />
                <CreateUserScreen hidden={this.state.loginInfo.token === "" && this.state.loginInfo.createUserScreen === "yes"} createUser={(k) => this.createUser(k)} handleUsernameChange={(k) => this.handleLoginInfoChange(k, 'username', 'yes')} handlePassword1Change={(k) => this.handleLoginInfoChange(k, 'password1', 'yes')} handlePassword2Change={(k) => this.handleLoginInfoChange(k, 'password2', 'yes')} handleEmailChange={(k) => this.handleLoginInfoChange(k, 'email', 'yes')} handleGoToLoginScreen={(k) => this.handleGoToLoginScreen(k)} />
                <KeywordBox clues={this.state.clues} hidden={cPage === 'keywordBox'} miniGame={this.state.miniGame} handleKeyword={(k) => this.checkKeyword(k)} handleChange={(k) => this.handleKeywordChange(k)} />
                <MapBox hidden={cPage === 'mapBox'} lat={this.state.hunts[0].lat} latPlus={this.state.hunts[0].latPlus} long={this.state.hunts[0].long} longPlus={this.state.hunts[0].longPlus} />
                <CrewBox hidden={cPage === 'crewBox'} team_desc={this.state.hunts[0].team_desc} />
                <StashBox hidden={cPage === 'stashBox'} hunt={this.state.hunts[0]} />
                <RankBox hidden={cPage === 'rankBox'} onLogoutCLick={() => this.logout()} />
                <Navbar hidden={this.state.loginInfo.token !== ""} onClick={(page) => this.handleClick(page)} />
            </div>
        )
    }
}

// ========================================

ReactDOM.render(
    <SC />,
    document.getElementById('root')
);